<template>
  <div class="small">
    <!-- <ChartLine :chartData="datacollection" :options="options" /> -->
    <ChartPie
      :label="theLabel"
      :labels="labels"
      :data="data"
      :colors="colors"
      :options="options"
    />
    <!-- <button @click="fillData()">Randomize</button> -->
  </div>
</template>

<script>
// import createToastMixin from "@/utils/create-toast-mixin";
// import ChartLine from "@/components/general/ChartLine.vue";
import ChartPie from "@/components/general/ChartPie.vue";

export default {
  name: "Test",
  components: {
    // ChartLine,
    ChartPie,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      theLabel: "Employees Chart",
      labels: [
        "Employees",
        "EmployeesActive",
        "EmployeesNotActive",
        "EmployeesArchived",
        "EmployeesNotArchived",
      ],
      colors: ["#00afb9", "#d90429", "#fee440", "#7b2cbf", "#38b000"],
      data: [25, 16, 2, 7, 18],
      options: {
        // title: {
        //   display: true,
        //   position: "bottom",
        //   text: "Custom Chart Title",
        // },
        responsive: true,
        maintainAspectRatio: false,
      },
      datacollection: {
        labels: [
          "totalCountEmployees",
          "totalCountEmployeesActive",
          "totalCountEmployeesNotActive",
          "totalCountEmployeesArchived",
          "totalCountEmployeesNotArchived",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#00afb9",
              "#d90429",
              "#fee440",
              "#7b2cbf",
              "#38b000",
            ],
            data: [25, 16, 2, 7, 18],
          },
        ],
      },
    };
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [
          "totalCountEmployees",
          "totalCountEmployeesActive",
          "totalCountEmployeesNotActive",
          "totalCountEmployeesArchived",
          "totalCountEmployeesNotArchived",
        ],
        datasets: [
          {
            // label: "Data One",
            backgroundColor: [
              "#00afb9",
              "#d90429",
              "#fee440",
              "#7b2cbf",
              "#38b000",
            ],
            // data: [this.getRandomInt(), this.getRandomInt()],
            data: [25, 16, 2, 7, 18],
          },
          // {
          //   label: "Data Two",
          //   backgroundColor: "#f87979",
          //   data: [this.getRandomInt(), this.getRandomInt()],
          // },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
  mounted() {
    // this.fillData();
  },
  watch: {},
  async created() {},
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 0px auto;
}
</style>
