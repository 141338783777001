import { render, staticRenderFns } from "./StoreDelete.vue?vue&type=template&id=6b47bebd&scoped=true&"
import script from "./StoreDelete.vue?vue&type=script&lang=js&"
export * from "./StoreDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b47bebd",
  null
  
)

export default component.exports