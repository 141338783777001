import { render, staticRenderFns } from "./AllowanceDelete.vue?vue&type=template&id=bc7fb358&scoped=true&"
import script from "./AllowanceDelete.vue?vue&type=script&lang=js&"
export * from "./AllowanceDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7fb358",
  null
  
)

export default component.exports