import { render, staticRenderFns } from "./OfficialHolidaysPlanDelete.vue?vue&type=template&id=59b28432&scoped=true&"
import script from "./OfficialHolidaysPlanDelete.vue?vue&type=script&lang=js&"
export * from "./OfficialHolidaysPlanDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b28432",
  null
  
)

export default component.exports