import { render, staticRenderFns } from "./EmployeeMovement.vue?vue&type=template&id=850d2870&scoped=true&"
import script from "./EmployeeMovement.vue?vue&type=script&lang=js&"
export * from "./EmployeeMovement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "850d2870",
  null
  
)

export default component.exports