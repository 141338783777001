var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[(_vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeNews.newsView))?_c('li',[_c('router-link',{class:_vm.currentPage == 'news' ? 'active' : '',attrs:{"to":"/news"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/news.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("news")))])])],1):_vm._e(),(_vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeOffers.offersView))?_c('li',[_c('router-link',{class:_vm.currentPage == 'offers' ? 'active' : '',attrs:{"to":"/offers"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/offers.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("offers")))])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeServicesReservations
          .servicesReservationView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeServicesReservations
          .servicesReservationViewPay
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.reservationsAndPayments",modifiers:{"reservationsAndPayments":true}}],class:_vm.currentPage == 'reservations-payments' ? 'active' : ''},[_vm._m(0),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("reservationsAndPayments"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"reservationsAndPayments"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeServicesReservations
                .servicesReservationView
            )
          )?_c('li',[_c('a',{class:_vm.currentSubPage == 'services-reservations' ? 'active' : '',on:{"click":function($event){return _vm.goToServicesReservations()}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/services-reservations.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("servicesReservations")))])])]):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeServicesReservations
                .servicesReservationViewPay
            )
          )?_c('li',[_c('a',{class:_vm.currentSubPage == 'services-payments' ? 'active' : '',on:{"click":function($event){return _vm.goToPayments()}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/services-payments.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("servicesPayments")))])])]):_vm._e()])])],1):_vm._e(),_c('li',[_c('a',{class:_vm.currentPage == 'historical-plans' ? 'active' : '',on:{"click":function($event){return _vm.goToHistoricalPlan()}}},[_vm._m(1),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("historicalPlans.modelName")))])])]),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeWalletTransaction
          .walletTransactionView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeTransactionPointsHistory
          .transactionPointsHistoryView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.pointsAndWallets",modifiers:{"pointsAndWallets":true}}],class:_vm.currentPage == 'points-wallets' ? 'active' : ''},[_vm._m(2),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pointsAndWallets.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"pointsAndWallets"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeWalletTransaction
                .walletTransactionView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'wallets' ? 'active' : '',attrs:{"to":{ name: 'Wallets' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/wallet.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pointsAndWallets.wallets.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeTransactionPointsHistory
                .transactionPointsHistoryView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'points-transactions' ? 'active' : '',attrs:{"to":{ name: 'PointsTransactions' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/points.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pointsAndWallets.points.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeClients.clientsView)
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'clients' ? 'active' : '',attrs:{"to":"/clients"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/clients.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("clients")))])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeTreasuries.treasuriesView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeTreasuriesTransaction
          .treasuriesTransactionsView
      ) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeRevenus.revenuesView) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeExpenses.expensesView)
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.finance",modifiers:{"finance":true}}],class:_vm.currentPage == 'finance' ? 'active' : ''},[_vm._m(3),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("finance"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"finance"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeTreasuries.treasuriesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'treasuries' ? 'active' : '',attrs:{"to":"/treasuries"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/treasuries.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theTreasuries")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeTreasuriesTransaction
                .treasuriesTransactionsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'treasuries-transactions' ? 'active' : '',attrs:{"to":"/treasuries-transactions"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/treasuries-transactions.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("treasuriesTransactions")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeRevenus.revenuesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'revenues' ? 'active' : '',attrs:{"to":"/revenues"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/revenues.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("revenues")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeExpenses.expensesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'expenses' ? 'active' : '',attrs:{"to":"/expenses"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/expenses.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("expenses")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeEmployees.employeesView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theEmployees",modifiers:{"theEmployees":true}}],class:_vm.currentPage == 'theEmployees' ? 'active' : ''},[_vm._m(4),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theEmployees"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theEmployees"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeEmployees.employeesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employees' ? 'active' : '',attrs:{"to":"/employees"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theEmployees")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'privileges' ? 'active' : '',attrs:{"to":"/privileges"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/privileges.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("privileges")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeBranches.branchesView)
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theBranches",modifiers:{"theBranches":true}}],class:_vm.currentPage == 'branches' ? 'active' : ''},[_vm._m(5),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theBranches"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theBranches"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeBranches.branchesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'branches' ? 'active' : '',attrs:{"to":"/branches"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branches.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theBranches")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegAdditionalSettings
                .additionalSettingsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'SettingsBranch' ? 'active' : '',attrs:{"to":"/settings-branch"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branches.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settingsBranch")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeReports.paymentReportView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeReports
          .treasuriesTransactionsReportView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeReports
          .servicesReservationsReportView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeStatistics
          .servicesReservationStatisticsView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeStatistics.generalStatisticsView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeStatistics
          .branchesInformationStatisticsView
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeStatistics
          .additionalSettingsStatisticsView
      )
    )?_c('li',[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.reportsAndStatistics",modifiers:{"reportsAndStatistics":true}}],class:_vm.currentPage == 'reportsAndStatistics' ? 'active' : ''},[_vm._m(6),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("reportsAndStatistics.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"reportsAndStatistics"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeReports.paymentReportView
            ) ||
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeReports
                .treasuriesTransactionsReportView
            ) ||
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeReports
                .servicesReservationsReportView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'reports' ? 'active' : '',attrs:{"to":"/reports"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reports.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("reports")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeStatistics
                .servicesReservationStatisticsView
            ) ||
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeStatistics
                .generalStatisticsView
            ) ||
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeStatistics
                .branchesInformationStatisticsView
            ) ||
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeStatistics
                .additionalSettingsStatisticsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'statistics' ? 'active' : '',attrs:{"to":"/statistics"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/statistics.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("statistics.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegAdditionalSettings
          .additionalSettingsView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],class:_vm.currentPage == 'settings' ? 'active' : ''},[_vm._m(7),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("settings"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"settings"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegAdditionalSettings
                .additionalSettingsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-other' ? 'active' : '',attrs:{"to":"/settings-other"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-other.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-other")))])])],1):_vm._e()])])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/services-reservations.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/historicalPlans.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/points-wallets.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/finance.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branches.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reportsAndStatistics.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg")}})])}]

export { render, staticRenderFns }