import axios from "axios";

export default class Branch {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.establishmentToken = "EST-1";
    this.establishmentNameCurrent = "";
    this.branchMangareEmployeeToken = "";
    this.branchMangareEmployeeNameCurrent = "";
    this.countryToken = "";
    this.countryNameCurrent = "";
    this.governateToken = "";
    this.governateNameCurrent = "";
    this.cityToken = "";
    this.cityNameCurrent = "";
    this.districtToken = "";
    this.districtNameCurrent = "";
    this.branchToken = "";
    this.branchNameEn = "";
    this.branchNameAr = "";
    this.branchNameUnd = "";
    this.branchFirstNumberCCWithCCName = "EG +20";
    this.branchFirstNumberCountryCodeName = "EG";
    this.branchFirstNumberCountryCode = "+20";
    this.branchFirstPhone = "";
    this.branchSecondNumberCCWithCCName = "EG +20";
    this.branchSecondNumberCountryCodeName = "EG";
    this.branchSecondNumberCountryCode = "+20";
    this.branchSecondPhone = "";
    this.branchLandLineCCWithCCName = "EG +20";
    this.branchLandLineCountryCodeName = "EG";
    this.branchLandLineCountryCode = "+20";
    this.branchLandLine = "";
    this.branchAddressEn = "";
    this.branchAddressAr = "";
    this.branchAddressUnd = "";
    this.branchLatitude = "";
    this.branchLongitude = "";
    this.branchEndLatitude = "";
    this.branchEndLongitude = "";
    this.branchDistance = "";
    this.branchNotes = "";
    this.branchImagePath = "";
  }
  fillData(branchData) {
    this.image = branchData.image;
    this.establishmentToken = branchData.establishmentToken;
    this.establishmentNameCurrent = branchData.establishmentNameCurrent;
    this.branchMangareEmployeeToken = branchData.branchMangareEmployeeToken;
    this.branchMangareEmployeeNameCurrent =
      branchData.branchMangareEmployeeNameCurrent;
    this.countryToken = branchData.countryToken;
    this.countryNameCurrent = branchData.countryNameCurrent;
    this.governateToken = branchData.governateToken;
    this.governateNameCurrent = branchData.governateNameCurrent;
    this.cityToken = branchData.cityToken;
    this.cityNameCurrent = branchData.cityNameCurrent;
    this.districtToken = branchData.districtToken;
    this.districtNameCurrent = branchData.districtNameCurrent;
    this.branchToken = branchData.branchToken;
    this.branchNameEn = branchData.branchNameEn;
    this.branchNameAr = branchData.branchNameAr;
    this.branchNameUnd = branchData.branchNameUnd;
    this.branchFirstNumberCCWithCCName = `${branchData.branchFirstNumberCountryCodeName} ${branchData.branchFirstNumberCountryCode}`;
    this.branchFirstNumberCountryCodeName =
      branchData.branchFirstNumberCountryCodeName;
    this.branchFirstNumberCountryCode = branchData.branchFirstNumberCountryCode;
    this.branchFirstPhone = branchData.branchFirstPhone;
    this.branchSecondNumberCCWithCCName = `${branchData.branchSecondNumberCountryCodeName} ${branchData.branchSecondNumberCountryCode}`;
    this.branchSecondNumberCountryCodeName =
      branchData.branchSecondNumberCountryCodeName;
    this.branchSecondNumberCountryCode =
      branchData.branchSecondNumberCountryCode;
    this.branchSecondPhone = branchData.branchSecondPhone;
    this.branchLandLineCCWithCCName = `${branchData.branchLandLineCountryCodeName} ${branchData.branchLandLineCountryCode}`;
    this.branchLandLineCountryCodeName =
      branchData.branchLandLineCountryCodeName;
    this.branchLandLineCountryCode = branchData.branchLandLineCountryCode;
    this.branchLandLine = branchData.branchLandLine;
    this.branchAddressEn = branchData.branchAddressEn;
    this.branchAddressAr = branchData.branchAddressAr;
    this.branchAddressUnd = branchData.branchAddressUnd;
    this.branchLatitude = branchData.branchLatitude;
    this.branchLongitude = branchData.branchLongitude;
    this.branchEndLatitude = branchData.branchEndLatitude;
    this.branchEndLongitude = branchData.branchEndLongitude;
    this.branchDistance = branchData.branchDistance;
    this.branchNotes = branchData.branchNotes;
    this.branchImagePath = branchData.branchImagePath;
  }

  async getBranchDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Branches/GetBranchDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&branchToken=${this.branchToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfBranches(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Branches/GetDialogOfBranches?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  // async addBranch(language, userAuthorizeToken) {
  //   if (this.image == "" || this.image == undefined) {
  //     var data = {
  //       image: this.image,
  //       language: language,
  //       userAuthorizeToken: userAuthorizeToken,
  //       establishmentToken: this.establishmentToken,
  //       branchNameEn: this.branchNameEn,
  //       branchNameAr: this.branchNameAr,
  //       branchNameUnd: this.branchNameUnd,
  //       branchFirstNumberCountryCodeName: this.branchFirstNumberCountryCodeName,
  //       branchFirstNumberCountryCode: this.branchFirstNumberCountryCode,
  //       branchFirstPhone: this.branchFirstPhone,
  //       branchSecondNumberCountryCodeName:
  //         this.branchSecondNumberCountryCodeName,
  //       branchSecondNumberCountryCode: this.branchSecondNumberCountryCode,
  //       branchSecondPhone: this.branchSecondPhone,
  //       branchLandLineCountryCodeName: this.branchLandLineCountryCodeName,
  //       branchLandLineCountryCode: this.branchLandLineCountryCode,
  //       branchLandLine: this.branchLandLine,
  //       branchAddressEn: this.branchAddressEn,
  //       branchAddressAr: this.branchAddressAr,
  //       branchAddressUnd: this.branchAddressUnd,
  //       branchLatitude: this.branchLatitude,
  //       branchLongitude: this.branchLongitude,
  //       branchEndLatitude: this.branchEndLatitude,
  //       branchEndLongitude: this.branchEndLongitude,
  //       branchDistance: this.branchDistance,
  //       branchNotes: this.branchNotes,
  //     };
  //   } else {
  //     var formData = new FormData();
  //     formData.append("image", this.image);
  //     formData.append("language", language);
  //     formData.append("userAuthorizeToken", userAuthorizeToken);
  //     formData.append("establishmentToken", this.establishmentToken);
  //     formData.append("branchNameEn", this.branchNameEn);
  //     formData.append("branchNameAr", this.branchNameAr);
  //     formData.append("branchNameUnd", this.branchNameUnd);
  //     formData.append(
  //       "branchFirstNumberCountryCodeName",
  //       this.branchFirstNumberCountryCodeName
  //     );
  //     formData.append(
  //       "branchFirstNumberCountryCode",
  //       this.branchFirstNumberCountryCode
  //     );
  //     formData.append("branchFirstPhone", this.branchFirstPhone);
  //     formData.append(
  //       "branchSecondNumberCountryCodeName",
  //       this.branchSecondNumberCountryCodeName
  //     );
  //     formData.append(
  //       "branchSecondNumberCountryCode",
  //       this.branchSecondNumberCountryCode
  //     );
  //     formData.append("branchSecondPhone", this.branchSecondPhone);
  //     formData.append(
  //       "branchLandLineCountryCodeName",
  //       this.branchLandLineCountryCodeName
  //     );
  //     formData.append(
  //       "branchLandLineCountryCode",
  //       this.branchLandLineCountryCode
  //     );
  //     formData.append("branchLandLine", this.branchLandLine);
  //     formData.append("branchAddressEn", this.branchAddressEn);
  //     formData.append("branchAddressAr", this.branchAddressAr);
  //     formData.append("branchAddressUnd", this.branchAddressUnd);
  //     formData.append("branchLatitude", this.branchLatitude);
  //     formData.append("branchLongitude", this.branchLongitude);
  //     formData.append("branchEndLatitude", this.branchEndLatitude);
  //     formData.append("branchEndLongitude", this.branchEndLongitude);
  //     formData.append("branchDistance", this.branchDistance);
  //     formData.append("branchNotes", this.branchNotes);
  //   }

  //   try {
  //     let response = "";
  //     if (this.image == "" || this.image == undefined) {
  //       response = await axios.post(`/Branches/AddBranchWithoutImage`, data);
  //     } else {
  //       response = await axios.post(`/Branches/AddBranchWithImage`, formData);
  //     }
  //     return response;
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }

  // async updateBranch(language, userAuthorizeToken) {
  //   if (this.image == "" || this.image == undefined) {
  //     var data = {
  //       image: this.image,
  //       language: language,
  //       userAuthorizeToken: userAuthorizeToken,
  //       branchToken: this.branchToken,
  //       establishmentToken: this.establishmentToken,
  //       branchNameEn: this.branchNameEn,
  //       branchNameAr: this.branchNameAr,
  //       branchNameUnd: this.branchNameUnd,
  //       branchFirstNumberCountryCodeName: this.branchFirstNumberCountryCodeName,
  //       branchFirstNumberCountryCode: this.branchFirstNumberCountryCode,
  //       branchFirstPhone: this.branchFirstPhone,
  //       branchSecondNumberCountryCodeName:
  //         this.branchSecondNumberCountryCodeName,
  //       branchSecondNumberCountryCode: this.branchSecondNumberCountryCode,
  //       branchSecondPhone: this.branchSecondPhone,
  //       branchLandLineCountryCodeName: this.branchLandLineCountryCodeName,
  //       branchLandLineCountryCode: this.branchLandLineCountryCode,
  //       branchLandLine: this.branchLandLine,
  //       branchAddressEn: this.branchAddressEn,
  //       branchAddressAr: this.branchAddressAr,
  //       branchAddressUnd: this.branchAddressUnd,
  //       branchLatitude: this.branchLatitude,
  //       branchLongitude: this.branchLongitude,
  //       branchEndLatitude: this.branchEndLatitude,
  //       branchEndLongitude: this.branchEndLongitude,
  //       branchDistance: this.branchDistance,
  //       branchNotes: this.branchNotes,
  //     };
  //   } else {
  //     var formData = new FormData();
  //     formData.append("image", this.image);
  //     formData.append("language", language);
  //     formData.append("userAuthorizeToken", userAuthorizeToken);
  //     formData.append("branchToken", this.branchToken);
  //     formData.append("establishmentToken", this.establishmentToken);
  //     formData.append("branchNameEn", this.branchNameEn);
  //     formData.append("branchNameAr", this.branchNameAr);
  //     formData.append("branchNameUnd", this.branchNameUnd);
  //     formData.append(
  //       "branchFirstNumberCountryCodeName",
  //       this.branchFirstNumberCountryCodeName
  //     );
  //     formData.append(
  //       "branchFirstNumberCountryCode",
  //       this.branchFirstNumberCountryCode
  //     );
  //     formData.append("branchFirstPhone", this.branchFirstPhone);
  //     formData.append(
  //       "branchSecondNumberCountryCodeName",
  //       this.branchSecondNumberCountryCodeName
  //     );
  //     formData.append(
  //       "branchSecondNumberCountryCode",
  //       this.branchSecondNumberCountryCode
  //     );
  //     formData.append("branchSecondPhone", this.branchSecondPhone);
  //     formData.append(
  //       "branchLandLineCountryCodeName",
  //       this.branchLandLineCountryCodeName
  //     );
  //     formData.append(
  //       "branchLandLineCountryCode",
  //       this.branchLandLineCountryCode
  //     );
  //     formData.append("branchLandLine", this.branchLandLine);
  //     formData.append("branchAddressEn", this.branchAddressEn);
  //     formData.append("branchAddressAr", this.branchAddressAr);
  //     formData.append("branchAddressUnd", this.branchAddressUnd);
  //     formData.append("branchLatitude", this.branchLatitude);
  //     formData.append("branchLongitude", this.branchLongitude);
  //     formData.append("branchEndLatitude", this.branchEndLatitude);
  //     formData.append("branchEndLongitude", this.branchEndLongitude);
  //     formData.append("branchDistance", this.branchDistance);
  //     formData.append("branchNotes", this.branchNotes);
  //   }

  //   try {
  //     let response = "";
  //     if (this.image == "" || this.image == undefined) {
  //       response = await axios.post(`/Branches/UpdateBranchWithoutImage`, data);
  //     } else {
  //       response = await axios.post(
  //         `/Branches/UpdateBranchWithImage`,
  //         formData
  //       );
  //     }
  //     return response;
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }

  async addOrUpdateBranch(language, userAuthorizeToken) {
    var data = {
      image: this.image,
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      branchToken: this.branchToken,
      establishmentToken: this.establishmentToken,
      branchMangareEmployeeToken: this.branchMangareEmployeeToken,
      countryToken: this.countryToken,
      governateToken: this.governateToken,
      cityToken: this.cityToken,
      districtToken: this.districtToken,
      branchNameEn: this.branchNameEn,
      branchNameAr: this.branchNameAr,
      branchNameUnd: this.branchNameUnd,
      branchFirstNumberCountryCodeName: this.branchFirstNumberCountryCodeName,
      branchFirstNumberCountryCode: this.branchFirstNumberCountryCode,
      branchFirstPhone: this.branchFirstPhone,
      branchSecondNumberCountryCodeName: this.branchSecondNumberCountryCodeName,
      branchSecondNumberCountryCode: this.branchSecondNumberCountryCode,
      branchSecondPhone: this.branchSecondPhone,
      branchLandLineCountryCodeName: this.branchLandLineCountryCodeName,
      branchLandLineCountryCode: this.branchLandLineCountryCode,
      branchLandLine: this.branchLandLine,
      branchAddressEn: this.branchAddressEn,
      branchAddressAr: this.branchAddressAr,
      branchAddressUnd: this.branchAddressUnd,
      branchLatitude: this.branchLatitude,
      branchLongitude: this.branchLongitude,
      branchEndLatitude: this.branchEndLatitude,
      branchEndLongitude: this.branchEndLongitude,
      branchDistance: this.branchDistance,
      branchNotes: this.branchNotes,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.branchToken == "" || this.branchToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(`/Branches/AddBranchWithImage`, formData);
        } else {
          response = await axios.post(`/Branches/AddBranchWithoutImage`, data);
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Branches/UpdateBranchWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Branches/UpdateBranchWithoutImage`,
            data
          );
        }
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveBranch(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      branchToken: this.branchToken,
    };

    try {
      let response = await axios.post(`/Branches/ArchiveBranch`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
